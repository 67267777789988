<script setup lang="ts">
import { useSkillApi } from '~/api/Skill'

const { getSkills } = useSkillApi()

const { data: skills } = await useAsyncData('skills', () => getSkills())
</script>

<template>
  <section class="pb-[3.125rem]">
    <div v-if="skills.data" class="container">
      <h2 class="my-[3.125rem] text-center text-yellow">
        {{ $t('skills.title') }}
      </h2>
      <div
        v-for="(item) in skills.data"
        :key="item.title"
        class="flex items-center border-b border-solid border-white px-2.5 py-5 text-white"
      >
        <NuxtImg
          class="size-[4.375rem]"
          :src="item.image"
          loading="lazy"
          width="70"
          height="70"
          :alt="item.title"
        />
        <p class="ml-auto uppercase">
          {{ item.title }}
        </p>
      </div>
    </div>
  </section>
</template>
