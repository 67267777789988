<script setup lang="ts">
defineProps<{
  image: string
}>()

const runtimeConfig = useRuntimeConfig()
</script>

<template>
  <section
    class="relative mx-auto min-h-[80vh] max-w-[120rem] after:absolute after:inset-0 after:bg-black_04 after:content-[''] md:after:bg-black_03"
  >
    <NuxtImg
      class="absolute inset-0 size-full object-cover"
      :src="`${runtimeConfig.public.apiBaseUrl}/storage/${image}`"
      sizes="sm:100vw md:100vw lg:100vw xl:100vw"
      width="1920"
      height="1080"
      alt="Banner"
    >
      <div class="container">
        <div class="relative z-[2] pt-[7.5rem] drop-shadow-[0.0625rem_0.0625rem_0.0625rem_#000] md:pt-[13.75rem]">
          <h1 class="text-yellow">
            {{ $t('banner.title') }}
          </h1>
          <h2 class="text-white">
            {{ $t('banner.subtitle') }}
          </h2>
          <h3 class="text-gray">
            {{ $t('banner.subtitle_country') }}
          </h3>
        </div>
      </div>
    </NuxtImg>
  </section>
</template>
