<script setup lang="ts">
import { useGlobalApi } from '~/api/Global'

const { getSettings } = useGlobalApi()

const { data: settings } = await useAsyncData('settings', () => getSettings())

definePageMeta({
  i18nKeys: ['header', 'banner', 'skills']
})
</script>

<template>
  <div>
    <main>
      <Banner :image="settings.banner_image" />
      <Skills />
    </main>
  </div>
</template>
