import type { ApiResponse } from '~/types/Global'
import type { Skill } from '~/types/Skill'

export const useSkillApi = () => {
  const runtimeConfig = useRuntimeConfig()

  const getSkills = (): Promise<ApiResponse<Skill>> => {
    return $fetch(`${runtimeConfig.public.apiBaseUrl}/api/skills`)
  }

  return {
    getSkills
  }
}
